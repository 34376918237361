import noAuth from "../guards/noAuth.js";
import auth from "../guards/auth.js";

const Login = () => import('@/views/login/Login.vue');
const User = () => import('@/views/user/User.vue');

/*
 | ------------------------------------------------------------------------------------
 |                      Login Routes
 | ------------------------------------------------------------------------------------
 */
export let loginRoutes = [
  {
    path: '/login',
    name: 'Login Page',
    component: Login,
    beforeEnter: noAuth
  },
  {
    path: '/user',
    name: 'User Page',
    component: User,
    beforeEnter: auth
  }
];
