<script>
import { useAuthStore } from "@/store/authentication.js";
import { usePreferencesStore } from "@/store/preferences.js";
import { useNotificationsStore } from "@/store/notification.js";

export default {
  methods: {
    clearMessages() {
      const notiStore = useNotificationsStore();
      notiStore.clearMessages();
    },
    notifySuccess(msg) {
      const notiStore = useNotificationsStore();
      notiStore.saveSuccessful(msg);
    },
    notifyError(msg) {
      const notiStore = useNotificationsStore();
      notiStore.addError(msg);
    },
    toggleDrawer() {
      this.preferences.toggleDrawer(!this.preferences.isDrawerOpen);
    },
    toggleDarkMode() {
      this.preferences.toggleDarkMode(!this.preferences.isDark);
    },
    toggleBrowserTimeZone() {
      this.preferences.updateTimezone(!this.preferences.timezone.useBrowserTime);
    },
  },
  computed: {
    global_messages() { const notiStore = useNotificationsStore(); notiStore.messages; },
    authentication() { const authstore = useAuthStore(); return authstore; },
    preferences() { const prefstore = usePreferencesStore(); return prefstore; },
    isDark() { return this.preferences.isDark; },
    isDrawerOpen() { return this.preferences.isDrawerOpen; },
  },
}
</script>
