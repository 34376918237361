/**
 * Here we import the Router
 */
import { createRouter, createWebHistory } from 'vue-router';

/**
 * Here we import the guards for our routes
 */
import { loginRoutes } from "./routes/login.js";
import { notFoundRoutes } from "./routes/notFound.js";
import { homeRoutes } from "./routes/home.js";
import { testingRoutes } from "./routes/testing.js";

/**
 * Get all the routes for the router
 */
let routes = [];

routes = routes.concat(
  loginRoutes,
  homeRoutes,
  testingRoutes,
  notFoundRoutes
);

export const router = createRouter({
  history: createWebHistory(),
  routes: routes
});
