<script>
import dayjs from 'dayjs';

export default {
  setup() {
    return { dayjs };
  },
  computed: {
    localTZ() { return new Date().toLocaleTimeString(undefined,{ timeZoneName:'short' }).split(' ')[2]; },
    locale() { return this.authentication.preferred_locale; },
  },
  methods: {
    asLocalDate(t) {
      //      if (t > 3600) dayjs.unix(t).format('YYYY-MM-DD HH:mm:ss');
      if (t > 3600) return new Date(t*1000).toLocaleDateString(this.locale);
      return '';
    },
    asLocalDateTime(t) {
      //      if (t > 3600) dayjs.unix(t).format('YYYY-MM-DD HH:mm:ss');
      if (t > 3600) return new Date(t*1000).toLocaleString(this.locale);
      return '';
    },
    asLocalDateTimeWithZone(t) {
      if (t > 3600) return new Date(t*1000).toLocaleString(this.locale) + ' (' + this.localTZ +')';
      return '';
    },
  },
}
</script>
