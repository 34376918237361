import { defineStore } from 'pinia'

export const useNotificationsStore = defineStore('notifications', {
  state: function() {
    return {
      messages: [],
      forceShow: false,
      notifications: [
        {
          id: 2,
          icon: null,
          title: 'Test Notification',
          message: 'This is a testing message that should be large enough to show something'
        }
      ],
    };
  },
  actions: {
    clearNotification: (state, id) => {
      // TODO handle clearing the notification from the server side
      this.notifications = this.notifications.filter(notification => notification.id !== id)
    },
    clearAllNotifications: (state) => {
      // TODO handle clearing the notifications from the server
      this.notifications = []
    },
    addMessage(message) {
      this.messages.push({ severity: message.severity, summary: message.msg, life:3000 });
      if (this.messages.length > 20) this.messages.shift();
    },
    clearMessages() {
      this.messages = [];
      this.forceShow = false;
    },
    saveSuccessful(v = undefined) {
      this.addMessage({ severity:'success', msg:v ?? 'Saved Successfully' });
    },
    addError(v = undefined) {
      if (Array.isArray(v)) {
        v.forEach((m) => {
          this.addMessage({ severity:'error', msg:m });
        });
      } else {
        this.addMessage({ severity:'error', msg:v ?? 'An error has occured' });
      }
    },
  },
});
