import auth from "../guards/auth";
import monitoring from "../guards/monitoring.js";
import superCheck from "../guards/superCheck.js";

export let homeRoutes = [
  {
    path: '/',
    name: 'Home Page',
    component: () => import('@/views/home/Home.vue'),
    beforeEnter: auth,
    redirect: '/patients'
  },
  {
    path: '/monitoring',
    name: 'Monitoring Page',
    component: () => import('@/views/monitoring/Monitoring.vue'),
    beforeEnter: monitoring
  },
  {
    path: '/monitoring2',
    name: 'Monitoring Page (Tech)',
    component: () => import('@/views/monitoring2/Monitoring.vue'),
    beforeEnter: monitoring
  },
  {
    path: '/patientmonitoring',
    name: 'Monitoring Page (Patient)',
    component: () => import('@/views/patientmonitoring/Monitoring.vue'),
    beforeEnter: monitoring
  },
  {
    path: '/patients',
    name: 'Patients Page',
    component: () => import('@/views/patient/Patients.vue'),
    beforeEnter: auth
  },
  {
    path: '/patients/:id',
    name: 'Patient Page',
    component: () => import('@/views/patient/Patient.vue'),
    beforeEnter: auth
  },
  {
    path: '/app/users',
    name: 'App Users Page',
    component: () => import('@/views/app/AppUsersView.vue'),
    beforeEnter: superCheck
  },
  {
    path: '/app/users/:id',
    name: 'App User Page',
    component: () => import('@/views/app/AppUserView.vue'),
    beforeEnter: superCheck
  },
  {
    path: '/tags',
    name: 'Tags Page',
    component: () => import('@/views/tags/Tags.vue'),
    beforeEnter: superCheck
  },
  {
    path: '/tags/:id',
    name: 'Tag Page',
    component: () => import('@/views/tags/Tag.vue'),
    beforeEnter: superCheck
  },
  {
    path: '/companies',
    name: 'Companies Page',
    component: () => import('@/views/companies/Companies.vue'),
    beforeEnter: superCheck
  },
  {
    path: '/companies/:id',
    name: 'Company Page',
    component: () => import('@/views/companies/Company.vue'),
    beforeEnter: superCheck
  }
];
