import { bootstrap } from './bootstrap';
bootstrap();

import storeMixin from './storeMixin.vue';
import utilMixin from './utilMixin.vue';

import PrimeVue from 'primevue/config';
/*
import 'primevue/resources/themes/saga-blue/theme.css'; // theme
//import "primevue/resources/themes/saga-blue/theme.css"
//import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css';
//import "primevue/resources/themes/aura-light-pink/theme.css";
//import "primevue/resources/themes/aura-dark-green/theme.css";
//import "primevue/resources/themes/md-light-indigo/theme.css"
//import 'primevue/resources/themes/aura-light-green/theme.css'
//import "primevue/resources/themes/aura-dark-blue/theme.css";
import 'primevue/resources/primevue.min.css';
*/
import Aura from '@primevue/themes/aura';
import Nora from '@primevue/themes/nora';
import { definePreset } from '@primevue/themes';
import 'primeicons/primeicons.css';

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

import PVToastService from 'primevue/toastservice';
import PVTooltip from 'primevue/tooltip';

import PVButton from 'primevue/button';
import PVSpeedDial from 'primevue/speeddial';
import PVProgressBar from 'primevue/progressbar';
import PVCard from 'primevue/card';
//import PVInputSwitch from 'primevue/inputswitch';
import PVInputSwitch from 'primevue/toggleswitch';
import PVDivider from 'primevue/divider';
import PVDataTable from 'primevue/datatable';
import PVColumn from 'primevue/column';
import PVCheckbox from 'primevue/checkbox';
import PVPaginator from 'primevue/paginator';
import PVMessage from 'primevue/message';
import PVDialog from 'primevue/dialog';
import PVInputText from 'primevue/inputtext';
import PVTextarea from 'primevue/textarea';
import PVDropdown from 'primevue/dropdown';
import ConfirmationService from 'primevue/confirmationservice';

import 'primeflex/primeflex.css';
import 'material-design-icons-iconfont/dist/material-design-icons.min.css';
import '@mdi/font/css/materialdesignicons.css';
//import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '../sass/app.scss';

import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

//import Vue2DatePicker from 'vue2-datepicker';
//import 'vue2-datepicker/index.css';
import Vue2DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

function setupV3(component, router, pinia) {
  
  const language = document.documentElement.lang;

  const i18n = createI18n({
    locale: language, //'en',
    fallbackLocale: 'en',
    messages: {
      en: {
        "verify-title": "Reset/Verify your account",
        "verify-success": "Your password has been reset.",
        "verify-password-label": "Password",
        "verify-password-confirm-label": "Confirm Password",
        "verify-error": "An error has occurred please contact support.",
        "verify-btn-submit": "Submit",

        "app-forgotten-success": "We have sent you an email to set a new password.",
        "app-forgotten-title": "Recover Password",
        "app-forgotten-email-label": "Email",
        "app-forgotten-confirm-email-label": "Confirm Email",
        "app-forgotten-btn-submit": "Submit"
      },
      de: {
        "verify-title": "Setzen Sie Ihr Konto zurück / überprüfen Sie es",
        "verify-success": "Dein Passwort wurde zurück gesetzt.",
        "verify-password-label": "Passwort",
        "verify-password-confirm-label": "Kennwort bestätigen",
        "verify-error": "Ein Fehler ist aufgetreten. Bitte wenden Sie sich an den Support.",
        "verify-btn-submit": "einreichen",

        "app-forgotten-success": "Wir haben Ihnen eine E-Mail gesendet, um ein neues Passwort festzulegen.",
        "app-forgotten-title": "Passwort-Wiederherstellung",
        "app-forgotten-email-label": "E-mail",
        "app-forgotten-confirm-email-label": "E-Mail-Adresse bestätigen",
        "app-forgotten-btn-submit": "Einreichen"
      },
      es: {
        "verify-title": "Restablecer / verificar su cuenta",
        "verify-success": "Tu contraseña ha sido restablecida.",
        "verify-password-label": "Contraseña",
        "verify-password-confirm-label": "Confirmar contraseña",
        "verify-error": "Se ha producido un error, póngase en contacto con soporte.",
        "verify-btn-submit": "Enviar",

        "app-forgotten-success": "Le hemos enviado un correo electrónico para establecer una nueva contraseña.",
        "app-forgotten-title": "Recuperar contraseña",
        "app-forgotten-email-label": "Correo electrónico",
        "app-forgotten-confirm-email-label": "Confirmar correo electrónico",
        "app-forgotten-btn-submit": "Enviar"
      }
    }
  });

  const app = createApp(component);

  app.config.errorHandler = (err, instance, info) => {

    // Handle the error globally
    console.error("Global error:", err);
    console.log("Vue instance:", instance);
    console.log("Error info:", info);
    
    // Add code for UI notifications, reporting or other error handling logic
  };
  
  app.use(i18n);
  app.use(pinia);
  app.use(router);

  const MyPreset = definePreset(Nora, {
     semantic: {
       primary: {
         50: '{blue.50}',
         100: '{blue.100}',
         200: '{blue.200}',
         300: '{blue.300}',
         400: '{blue.400}',
         500: '{blue.500}',
         600: '{blue.600}',
         700: '{blue.700}',
         800: '{blue.800}',
         900: '{blue.900}',
         950: '{blue.950}'
       },
       colorScheme: {
         light: {
           primary: {
             color: 'rgb(65, 143, 222)',
           },
           secondary: {
             color: 'rgb(245, 245, 245)',
           },
           highlight: {
             color: 'rgb(245, 245, 245)',
           },
         },
         dark: {
           color: 'rgb(245, 245, 245)',
//                        color: '#F5F5F5',
         },
       }
     }
  });
  
  app.use(PrimeVue, {
    ripple:false,
    theme: {
      preset: MyPreset,
      options: {
        prefix: 'p',
//        darkModeSelector: 'system',
        darkModeSelector: '.ym-dark', //system',
        cssLayer: false
      }
    }
  });
  app.use(Donut);
  app.use(PVToastService);
  app.directive('tooltip', PVTooltip);
  app.component('PVButton', PVButton);
  app.component('PVSpeedDial', PVSpeedDial);
  app.component('PVProgressBar', PVProgressBar);
  app.component('PVCard', PVCard);
  app.component('PVInputSwitch', PVInputSwitch);
  app.component('PVDivider', PVDivider);
  app.component('PVDataTable', PVDataTable);
  app.component('PVColumn', PVColumn);
  app.component('PVCheckbox', PVCheckbox);
  app.component('PVPaginator', PVPaginator);
  app.component('PVMessage', PVMessage);
  app.component('PVDialog', PVDialog);
  app.component('PVInputText', PVInputText);
  app.component('PVTextarea', PVTextarea);
  app.component('PVDropdown', PVDropdown);
  app.component('ConfirmationService', ConfirmationService);
  app.component('Vue2DatePicker', Vue2DatePicker);
  app.mixin(storeMixin);
  app.mixin(utilMixin);
  app.mount("#app");

  return app;
}

export function setup(component, router, pinia) {
  try {
    return setupV3(component, router, pinia);
  } catch(e) {
    console.log(e);
  }
};

