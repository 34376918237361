import { defineStore } from 'pinia'

export const usePreferencesStore = defineStore('preferences', {
  persist: true,
  state: function() {
    return {
      isDark: false,
      isDrawerOpen: false,
      transitions: true,
      monitoring: {
        search: '',
        companies: [],
        importance: false,
        tableView: false,
        status:null,
      },
      tags: {
        search: '',
        tableView: true,
        status: null,
        companies: [],
      },
      company: {
        search: '',
        tableView: true,
        status: null,
      },
      appusers: {
        search: '',
        tableView: true,
        status: null,
        companies: [],
      },
      companies: null,
      barcoding: {
        default: null,
        preference: null
      },
      timezone: {
        useBrowserTime: false
      },
      patient: {
        tableView: false,
        search: '',
        status:null,
        companies: [],
      },
      reporting: {
        tableView: false,
        search: '',
        status:null,
        companies: [],
      },
      appusers: {
        tableView: false,
        search: '',
        status:null,
      },
      absoluteScheduleTableView: false,
    };
  },
  actions: {
    toggleDrawer(status = false) {
      this.isDrawerOpen = status;
    },
    toggleDarkMode(status = false) {
      this.isDark = status;
    },
    updatePatientSearch(payload) {
      this.patient.search = payload;
    },
    updatePatientFilters(payload) {
      this.patient.search = payload;
    },
    updatePatientsTableView(payload) {
      this.patientsTableView = payload;
    },
    updateAppUsersTableView(payload) {
      this.appusers.tableView = payload;
    },
    updateTagsTableView(payload) {
      this.tags.tableView = payload;
    },
    updateMonitoringTableView(payload) {
      this.monitoring.tableView = payload;
    },
    updateAbsoluteScheduleTableView(payload) {
      this.absoluteScheduleTableView = payload;
    },
    updateMonitoringSearch(payload) {
      this.monitoring.search = payload;
    },
    updateAppUsersSearch(payload) {
      this.appuser.search = payload;
    },
    updateTagFilters(payload) {
      this.tags.search = payload;
    },
    updateTagsTableView(payload) {
      this.tags.tableView = payload;
    },
    updateCompanyFilters(payload) {
      this.companies = payload;
    },
    updateCompanySearch(payload) {
      this.company.search = payload;
    },
    updateCompaniesTableView(payload) {
      this.company.tableView = payload;
    },
    updateDefaultCamera(payload) {
      this.barcoding.default = payload;
    },
    updatePreference(payload) {
      this.barcoding.preference = payload;
    },
    updateTimezone(payload) {
      if (this.timezone == undefined) {
        this.timezone = {
          useBrowserTime: false
        };
      }
      this.timezone.useBrowserTime = payload;
      if (payload === false) {
        delete window.axios.defaults.headers.common['X-TIMEZONE-OFFSET-MINUTES'];
        return;
      }
      const date = new Date();
      window.axios.defaults.headers.common['X-TIMEZONE-OFFSET-MINUTES'] = date.getTimezoneOffset();
    },
    resetPreferences() {
      const endpoints = ['monitoring', 'patients', 'tags', 'companies', 'barcoding', 'timezone'];
      for (const endpoint of endpoints) {
        if (this[endpoint] != null) {
          for (const [key, value] of Object.entries(this[endpoint])) {
            if (this[endpoint][key] != null) {
              this[endpoint][key] = null;
            }
          }
        }
      }
    },
  },
});
