import { useAuthStore } from "@/store/authentication.js";

export default (to, from, next) => {
  const authentication = useAuthStore();
  if (!authentication.access?.superAdmin && !authentication.access?.permissions?.monitoring?.view) {
    next('/');
  } else {
    next();
  }
}
